var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    ref: "file",
    staticClass: "govuk-file-upload",
    attrs: {
      id: _vm.id,
      name: _vm.name,
      type: "file",
      "aria-describedby": _vm.ariaDescribedBy
    },
    on: { change: _vm.onChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }